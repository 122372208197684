import { formaterDecimalBRServer } from '@/utilities/Utils';

export function perfilFormatServer(perfilRequest) {

    if (perfilRequest != null) {

        let newListPerfilMenu = [];
        
        perfilRequest.margemMinimaProdutoNacional = formaterDecimalBRServer(perfilRequest.margemMinimaProdutoNacional)
        perfilRequest.margemMinimaProdutoImportado = formaterDecimalBRServer(perfilRequest.margemMinimaProdutoImportado)

        if (perfilRequest.listPerfilMenu !== null && perfilRequest.listPerfilMenu !== undefined) {
            perfilRequest.listPerfilMenu.forEach(itemPerfilMenu => {
                if (itemPerfilMenu && itemPerfilMenu.menuSelected) {
                    newListPerfilMenu.push({
                        idMenu: itemPerfilMenu.menuSelected.id,
                    })

                    perfilRequest.listPerfilMenu = newListPerfilMenu;
                }
            })
        }
    }

    return perfilRequest;
}
