
import Vue from 'vue'

const actions = {

    async DashboardPrincipal(_, { ano, status }) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`dashboard/dashboard-principal/${ano}/${status}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listDashboardPrincipal = result.data.result;

                return listDashboardPrincipal;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao listar dashboard principal: [${ex}]`
            };
        }
    },

    async DashboardPrincipalGrafico(_, { ano, status, tipoGrafico }) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`dashboard/dashboard-principal-grafico/${ano}/${status}/${tipoGrafico}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listDashboardPrincipalGrafico = result.data.result;

                return listDashboardPrincipalGrafico;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao listar dashboard principal gráfico: [${ex}]`
            };
        }
    },
}

export default {
    namespaced: true,
    actions
}