
import Vue from 'vue'

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async ListPedidosPorStatus(_, pedidoPorStatusRequest) {

        try {
            const result = await Vue.prototype.$spTechApi.post("relatorio/pedidos-por-status", pedidoPorStatusRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listRelatorio = result.data.result;

                return listRelatorio;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar relatório de pedidos por status: [${ex}]`
            };
        }
    },

    async ListCotacaoPedido(_, cotacaoPedidoRequest) {

        try {
            const result = await Vue.prototype.$spTechApi.post("relatorio/cotacao-pedido", cotacaoPedidoRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listRelatorio = result.data.result;

                return listRelatorio;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar relatório de cotações / pedidos: [${ex}]`
            };
        }
    },

}

export default {
    namespaced: true,
    actions
}
