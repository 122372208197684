import { format } from '@/directives/v-formatMoney/utils' //busca da diretiva v-formatMoney
import Vue from 'vue'
import store from '@/store/store'

function showLoading() {
	store.commit('Loader', true);
}

function hideLoading() {
	store.commit('Loader', false);
}

function openSideMenu(open) {
	store.commit('OpenMenuSide', open);
}

function formaterDecimalBR(value, precision = 2) {
	if (value) {

		var defaults = {
			prefix: '',
			suffix: '',
			thousands: '.',
			decimal: ',',
			precision: precision
		};

		let valorArray = value.toString().split(".");
			
		if (valorArray[1] != undefined) {

			const qtdDecimal = valorArray[1].length;

			if (qtdDecimal > 2) {
				defaults.precision = qtdDecimal;
			} else if (qtdDecimal < 2) {
				value = value.toFixed(2);// caso retornar da api uma case decimal, quando for valor redondo, EX: 100.6, forçar daus casas decimais
			}

		}else{
			value = value.toFixed(2);
		}
 
		return format(value, defaults);

	}

	if (value == null || value == 0) { return "0,00"; }
}

function formaterDecimalBRServer(value) {
	
	if(value == undefined) {
		return value;
	}
	else {
		value = value.toString()
		.replaceAll('$', '')
		.replaceAll('£', '')
		.replaceAll('€', '')
		.replaceAll('R', '')
		.replaceAll(' ', '')
		.replaceAll('.', '')
		.replaceAll(',','.');

		return value;
	}
}

function formatDateToServer(date) {

	if (date !== null) {
		var dateSplit = date.toString().split('/');

		return dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0];
	}

	return null;
}

function formatarDataPtBR(data) {

	if (data !== null) {
		var dataSplit = data.split('-');

		return dataSplit[0] + "-" + dataSplit[1] + "-" + dataSplit[2].split('T')[0];
	}
	return null;
}

function formatarDataFront(data) {

		if (data !== null) {

			data = data.split('T');

			return data[0];
		}

		return null;
}

function formatarDataPadraoPtBR(date) {
	if (!date) return null

	const [year, month, day] = date.split('-')
	return `${day}/${month}/${year}`
}

function formatarDataPadraoPtBRCompleta(data) {

	if (data !== null) {

		var dataSplit = data.trim().split('-')[0];

		return dataSplit;
	}

	return null;
}

function onlyNumber(evt) {

	evt = (evt) ? evt : window.event;

	var charCode = (evt.which) ? evt.which : evt.keyCode;

	if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
		evt.preventDefault();
	} 
	else {
		return true;
	}
}

function removeAcentos(str) 
{
	let com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";

	let sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
	let novastr="";
	for(let i=0; i<str.length; i++) {
		let troca=false;
		for (let a=0; a<com_acento.length; a++) {
			if (str.substr(i,1)==com_acento.substr(a,1)) {
				novastr+=sem_acento.substr(a,1);
				troca=true;
				break;
			}
		}
		if (troca==false) {
			novastr+=str.substr(i,1);
		}
	}
	return novastr;
}

function showToast(state, title, content) {

	var timer = 8000;
	var foreColor = "#FFFFFF";
	var titleFontColor = "#FFFFFF";
	var iconFontColor = "#FFFFFF";
	var messageFontColor = "#FFFFFF";
	var fxIn = "bounceInLeft";
	var fxOut = "fadeOutRight"

	switch (state) {

		case "info":
			Vue.prototype.toast.info(content, title, {transitionIn:fxIn, transitionOut: fxOut, progressBarColor: foreColor, color: foreColor, backgroundColor: 'rgba(30, 136, 229,1)', titleColor: titleFontColor, icon: 'mdi mdi-information-variant', iconColor: iconFontColor, messageColor: messageFontColor, timeout: timer });
			break;

		case "warning":
			Vue.prototype.toast.warning(content, title, {transitionIn:fxIn, transitionOut: fxOut, progressBarColor: foreColor,  color: foreColor, backgroundColor: 'rgba(255, 152, 0,1)', titleColor: titleFontColor, icon: 'mdi mdi-comment-alert-outline', iconColor: iconFontColor, messageColor: messageFontColor, timeout: timer });
			break;

		case "error":
			Vue.prototype.toast.error(content, title, {transitionIn:fxIn, transitionOut: fxOut, progressBarColor: foreColor, color: foreColor, backgroundColor: 'rgba(255, 0, 69,1)', titleColor: titleFontColor, icon: 'mdi mdi-alert-decagram-outline', iconColor: iconFontColor, messageColor: messageFontColor, timeout: timer })
			break;

		case "success":
			Vue.prototype.toast.success(content, title, {transitionIn:fxIn, transitionOut: fxOut, progressBarColor: foreColor, color: foreColor, backgroundColor: 'rgba(76, 175, 80,1)', titleColor: titleFontColor, icon: 'mdi mdi-check', iconColor: iconFontColor, messageColor: messageFontColor, timeout: timer });
			break;
	}
}

function showDialog(text, func, params) {
	const options = {
		html: true, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
		loader: false, // set to true if you want the dailog to show a loader after click on "proceed"
		reverse: false, // switch the button positions (left to right, and vise versa)
		okText: 'OK',
		cancelText: 'Cancelar',
		animation: 'bounce', // Available: "zoom", "bounce", "fade"
	}

	Vue.prototype.$dialog.confirm(text, options).then(() => func(params))
}

function orderBy( a, b, type, field ) {

	if (type == 0) { //asc
		return a[field] < b[field] ? -1 : 1;
	}
	else {
		return a[field] > b[field] ? -1 : 1;
	}
}

export {
	formaterDecimalBR, 
	formaterDecimalBRServer, 
	formatDateToServer, 
	formatarDataPtBR, 
	formatarDataPadraoPtBR,
	showToast, 
	showDialog, 
	showLoading, 
	hideLoading, 
	openSideMenu, 
	formatarDataFront, 
	onlyNumber, 
	removeAcentos, 
	formatarDataPadraoPtBRCompleta,
	orderBy
} 