
import Vue from 'vue'
import { showToast } from '@/utilities/Utils'

const getDefaultState = () => {
    return {
        pedidoNotificacaoEmail: {
            id: 0,
            status: 0,
            listIDPerfil: "",
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async List() {

        try {
            const result = await Vue.prototype.$spTechApi.get("pedidoNotificacaoEmail/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listNotificacaoEmail = result.data.result;

                return listNotificacaoEmail;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar configurações das notificações de pedidos: [${ex}]`
            };
        }
    },

    async GetById(_, id) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`pedidoNotificacaoEmail/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Erro ao obter configuração`
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                showToast("error", "Erro!", error.response.data);
            }
            else {
                showToast("error", "Erro!", error);
            }
        }
    },

    async CreateUpdate(_, pedidoNotificacaoEmailRequest) {

        try {

            const result = await Vue.prototype.$spTechApi.post(`pedidoNotificacaoEmail/create-update`, pedidoNotificacaoEmailRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Registro atualizado com sucesso!";

                if (pedidoNotificacaoEmailRequest.id === 0) {
                    msg = "Registro criado com sucesso!";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao criar/atualizar o registro!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Erro ao criar/atualizar o registro: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Erro ao criar/atualizar o registro: [${error}]`
                };
            }
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.pedidoNotificacaoEmail, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.pedidoNotificacaoEmail;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
