import { formaterDecimalBRServer } from '@/utilities/Utils';

export function orcamentoFormatServer(orcamentoRequest) {

    if (orcamentoRequest.status === null) {
        orcamentoRequest.status = 0;
    }

    if (orcamentoRequest.codigoFechado === null) {
        orcamentoRequest.codigoFechado = 0;
    }

    if (orcamentoRequest.importado === null) {
        orcamentoRequest.importado = 0;
    }

    orcamentoRequest.descontoUsuario = formaterDecimalBRServer(orcamentoRequest.descontoUsuario);
    
    orcamentoRequest.listItemsOrcamento.forEach (itemOrcamento => {
        
        itemOrcamento.precoBruto = formaterDecimalBRServer(itemOrcamento.precoBruto);
        itemOrcamento.precoSemImpostos = formaterDecimalBRServer(itemOrcamento.precoSemImpostos);
        itemOrcamento.aliqPIS = formaterDecimalBRServer(itemOrcamento.aliqPIS);
        itemOrcamento.aliqCOFINS = formaterDecimalBRServer(itemOrcamento.aliqCOFINS);
        itemOrcamento.aliqICMS = formaterDecimalBRServer(itemOrcamento.aliqICMS);
        itemOrcamento.aliqIPI = formaterDecimalBRServer(itemOrcamento.aliqIPI);
        itemOrcamento.valorTotal = formaterDecimalBRServer(itemOrcamento.valorTotal);
    })
    
    return orcamentoRequest;
}
