import { formaterDecimalBRServer, formaterDecimalBR } from '@/utilities/Utils';

export function produtoFormatServer(produtoRequest) {

    if (produtoRequest.status === null) {
        produtoRequest.status = 0;
    }

    if (produtoRequest.codigoFechado === null) {
        produtoRequest.codigoFechado = 0;
    }

    if (produtoRequest.importado === null) {
        produtoRequest.importado = 0;
    }

    if (produtoRequest.visivelUsuarioInterno === null) {
        produtoRequest.visivelUsuarioInterno = 0;
    }

    if (produtoRequest.certificacaoEX === null) {
        produtoRequest.certificacaoEX = 0;
    }

    if (produtoRequest.prazoEntregaDefinido === null) {
        produtoRequest.prazoEntregaDefinido = 0;
    }

    if (produtoRequest.prazoEntrega == "") {
        produtoRequest.prazoEntrega = 0;
    }

    if (produtoRequest.compraMinima === null) {
        produtoRequest.compraMinima = 0;
        produtoRequest.compraMinimaQtde = 1;
    }

    if (produtoRequest.obsoleto === null) {
        produtoRequest.obsoleto = 0;
        produtoRequest.textoObsoleto = "";
    }

    produtoRequest.precoCusto = formaterDecimalBRServer(produtoRequest.precoCusto);
    produtoRequest.idTipoProduto = produtoRequest.tipoProdutoSelected.id;
    produtoRequest.idUnidadeMedida = produtoRequest.unidadeMedidaSelected.id;
    produtoRequest.idCambio = produtoRequest.cambioSelected.id;
    produtoRequest.idNcm = produtoRequest.ncmSelected.id;
    produtoRequest.idEstadoOrigem = 0;
    produtoRequest.idCidade = 0;
    
    if (produtoRequest.cidadeSelected != null && produtoRequest.cidadeSelected.id != undefined) {
        produtoRequest.idCidade = produtoRequest.cidadeSelected.id;
    }

    if (produtoRequest.estadoOrigemSelected != null && produtoRequest.estadoOrigemSelected.id != undefined) {
        produtoRequest.idEstadoOrigem = produtoRequest.estadoOrigemSelected.id;
    }
    produtoRequest.aliqPIS = formaterDecimalBRServer(produtoRequest.aliqPIS);
    produtoRequest.aliqCOFINS = formaterDecimalBRServer(produtoRequest.aliqCOFINS);
    produtoRequest.margem = formaterDecimalBRServer(produtoRequest.margem);
    produtoRequest.idCambioICP = produtoRequest.cambioICPSelected.id;
    produtoRequest.valorICP = formaterDecimalBRServer(produtoRequest.valorICP);
    produtoRequest.landedCost = formaterDecimalBRServer(produtoRequest.landedCost);
    produtoRequest.desconto = formaterDecimalBRServer(produtoRequest.desconto);
    produtoRequest.precoLista = formaterDecimalBRServer(produtoRequest.precoLista);
    produtoRequest.valorMoedaICP = formaterDecimalBRServer(produtoRequest.valorMoedaICP);

    produtoRequest.listParametroRequest.forEach (itemParametro => {

        if (produtoRequest.id == 0) {
            itemParametro.id = 0;
        }

        if (itemParametro.listParametroItemRequest != null && itemParametro.listParametroItemRequest != undefined) {
            
            itemParametro.listParametroItemRequest.forEach (itemParametroItem => {

                if (produtoRequest.id == 0) {
                    itemParametroItem.id = 0;
                }
                
                itemParametroItem.precoCusto = formaterDecimalBRServer(itemParametroItem.precoCusto);
                itemParametroItem.margem = formaterDecimalBRServer(itemParametroItem.margem);
                
                itemParametroItem.idCambioICP = produtoRequest.idCambioICP;
                itemParametroItem.valorICP = formaterDecimalBRServer(itemParametroItem.valorICP);
                itemParametroItem.landedCost = formaterDecimalBRServer(itemParametroItem.landedCost);
                itemParametroItem.desconto = formaterDecimalBRServer(itemParametroItem.desconto);
                itemParametroItem.precoLista = formaterDecimalBRServer(itemParametroItem.precoLista);
                itemParametroItem.valorMoedaICP = formaterDecimalBRServer(itemParametroItem.valorMoedaICP);
                
                if (itemParametroItem.status === null || itemParametroItem.status == false) {
                    itemParametroItem.status = 0;
                }
                else if (itemParametroItem.status == true) {
                    itemParametroItem.status = 1;
                }
                
                if (itemParametroItem.certificacaoEX === null || itemParametroItem.certificacaoEX == false) {
                    itemParametroItem.certificacaoEX = 0;
                }
                else if (itemParametroItem.certificacaoEX == true) {
                    itemParametroItem.certificacaoEX = 1;
                }
                
                if (itemParametroItem.obsoleto === null || itemParametroItem.obsoleto == false) {
                    itemParametroItem.obsoleto = 0;
                    itemParametroItem.textoObsoleto = "";
                }
                else if (itemParametroItem.obsoleto == true) {
                    itemParametroItem.obsoleto = 1;
                }
            })
        }
    })

    let newListSubEstrutura = [];

    produtoRequest.listSubEstruturaRequest.forEach (itemSubEstrutura => {

        if (produtoRequest.id == 0) {
            itemSubEstrutura.id = 0;
        }

        itemSubEstrutura.landedCost = formaterDecimalBRServer(itemSubEstrutura.landedCost);
        itemSubEstrutura.desconto = formaterDecimalBRServer(itemSubEstrutura.desconto);
        itemSubEstrutura.precoLista = formaterDecimalBRServer(itemSubEstrutura.precoLista);
        itemSubEstrutura.valorMoedaICP = formaterDecimalBRServer(itemSubEstrutura.valorMoedaICP);
        itemSubEstrutura.valorICP = formaterDecimalBRServer(itemSubEstrutura.valorICP);
        itemSubEstrutura.custo = formaterDecimalBRServer(itemSubEstrutura.custo);

        if (itemSubEstrutura.nome != null && itemSubEstrutura.nome != undefined && itemSubEstrutura.nome != "") {
            newListSubEstrutura.push(itemSubEstrutura);
        }

    })
    produtoRequest.listSubEstruturaRequest = newListSubEstrutura;

    produtoRequest.listPrazoEntrega.forEach (itemPrazoEntrega => {
        if (itemPrazoEntrega.qtde === null) {
            itemPrazoEntrega.qtde = 0;
        }

        if (itemPrazoEntrega.prazoEntregaDefinido === null || itemPrazoEntrega.qtde == 0) {
            itemPrazoEntrega.prazoEntregaDefinido = 0;
        }

        if (itemPrazoEntrega.prazoEntrega == "" || 
            itemPrazoEntrega.prazoEntrega == null || 
            itemPrazoEntrega.prazoEntregaDefinido == 0) {
            itemPrazoEntrega.prazoEntrega = 0;
        }
    })
    
    return produtoRequest;
}

export function produtoFormatFront(produtoRequest) {

    produtoRequest.precoCusto = formaterDecimalBR(produtoRequest.precoCusto);
    produtoRequest.aliqPIS = formaterDecimalBR(produtoRequest.aliqPIS);
    produtoRequest.aliqCOFINS = formaterDecimalBR(produtoRequest.aliqCOFINS);
    produtoRequest.margem = formaterDecimalBR(produtoRequest.margem);
    produtoRequest.valorICP = formaterDecimalBR(produtoRequest.valorICP);
    produtoRequest.landedCost = formaterDecimalBR(produtoRequest.landedCost);
    produtoRequest.desconto = formaterDecimalBR(produtoRequest.desconto);
    produtoRequest.precoLista = formaterDecimalBR(produtoRequest.precoLista);
    produtoRequest.valorMoedaICP = formaterDecimalBR(produtoRequest.valorMoedaICP);

    produtoRequest.listParametroRequest.forEach (itemParametro => {

        if (itemParametro.listParametroItemRequest != null && itemParametro.listParametroItemRequest != undefined) {
            
            itemParametro.listParametroItemRequest.forEach (itemParametroItem => {
                itemParametroItem.margem = formaterDecimalBR(itemParametroItem.margem);
                itemParametroItem.landedCost = formaterDecimalBR(itemParametroItem.landedCost);
                itemParametroItem.desconto = formaterDecimalBR(itemParametroItem.desconto);
                itemParametroItem.precoLista = formaterDecimalBR(itemParametroItem.precoLista);
                itemParametroItem.valorMoedaICP = formaterDecimalBR(itemParametroItem.valorMoedaICP);
                itemParametroItem.valorICP = formaterDecimalBR(itemParametroItem.valorICP);
                itemParametroItem.precoCusto = formaterDecimalBR(itemParametroItem.precoCusto);
            })
        }
    })

    produtoRequest.listSubEstruturaRequest.forEach (itemSubEstrutura => {

        itemSubEstrutura.landedCost = formaterDecimalBR(itemSubEstrutura.landedCost);
        itemSubEstrutura.desconto = formaterDecimalBR(itemSubEstrutura.desconto);
        itemSubEstrutura.precoLista = formaterDecimalBR(itemSubEstrutura.precoLista);
        itemSubEstrutura.valorMoedaICP = formaterDecimalBR(itemSubEstrutura.valorMoedaICP);
        itemSubEstrutura.valorICP = formaterDecimalBR(itemSubEstrutura.valorICP);
        itemSubEstrutura.custo = formaterDecimalBR(itemSubEstrutura.custo);

    })
    
    return produtoRequest;
}