
import Vue from 'vue'

const actions = {

    async List() {

        try {
            const result = await Vue.prototype.$spTechApi.get("comum/segmento/list-segmentos",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listSegmentos = result.data.result;

                return listSegmentos;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar segmentos: [${ex}]`
            };
        }
    },
}

export default {
    namespaced: true,
    actions
}
