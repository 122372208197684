import { formaterDecimalBRServer } from '@/utilities/Utils';

export function pedidoFormatServer(pedidoRequest) {

    if (pedidoRequest.status === null) {
        pedidoRequest.status = 0;
    }

    if (pedidoRequest.codigoFechado === null) {
        pedidoRequest.codigoFechado = 0;
    }

    if (pedidoRequest.importado === null) {
        pedidoRequest.importado = 0;
    }
    
    if (pedidoRequest.condicaoPagamentoSelected != null && pedidoRequest.condicaoPagamentoSelected != undefined) {
        pedidoRequest.idCondicaoPagamento = pedidoRequest.condicaoPagamentoSelected.id;
    }
    
    if (pedidoRequest.formaPagamentoSelected != null && pedidoRequest.formaPagamentoSelected != undefined) {
        pedidoRequest.idFormaPagamento = pedidoRequest.formaPagamentoSelected.id;
    }
    
    if (pedidoRequest.tipoFreteSelected != null && pedidoRequest.tipoFreteSelected != undefined) {
        pedidoRequest.idTipoFrete = pedidoRequest.tipoFreteSelected.id;
    }
    
    if (pedidoRequest.clienteSelected != null && pedidoRequest.clienteSelected != undefined) {
        pedidoRequest.idCliente = pedidoRequest.clienteSelected.id;
    }

    if (pedidoRequest.naturezaSelected != null && pedidoRequest.naturezaSelected != undefined) {
        pedidoRequest.idNatureza = pedidoRequest.naturezaSelected.idProcessed;
    }

    if (pedidoRequest.transportadoraSelected != null && pedidoRequest.transportadoraSelected != undefined) {
        pedidoRequest.idTransportadora = pedidoRequest.transportadoraSelected.id;
    }

    pedidoRequest.valorFrete = formaterDecimalBRServer(pedidoRequest.valorFrete);
    pedidoRequest.idTipoCliente = pedidoRequest.tipoCliente;

    pedidoRequest.descontoUsuario = formaterDecimalBRServer(pedidoRequest.descontoUsuario);
    
    return pedidoRequest;
}
