import { formaterDecimalBRServer } from '@/utilities/Utils';

export function keyAccountFormatServer(keyAccountRequest) {

    if (keyAccountRequest.status === null) {
        keyAccountRequest.status = 0;
    }

    if (keyAccountRequest.clienteSelected != null && keyAccountRequest.clienteSelected != undefined) {
        keyAccountRequest.idCliente = keyAccountRequest.clienteSelected.id;
    }

    keyAccountRequest.listItemsKeyAccount.forEach (itemkeyAccount => {

        if (keyAccountRequest.id == 0) {
            itemkeyAccount.id = 0;
        }
        
        itemkeyAccount.preco = formaterDecimalBRServer(itemkeyAccount.preco);

        if (itemkeyAccount.cambioSelected != null && itemkeyAccount.cambioSelected != undefined) {
            itemkeyAccount.idCambio = itemkeyAccount.cambioSelected.id;
        }
    })
    
    return keyAccountRequest;
}
