import { formaterDecimalBRServer, formatDateToServer } from '@/utilities/Utils';

export function campanhaCreditoFormatServer(campanhaCreditoRequest) {

    if (campanhaCreditoRequest != null) {

        let newListItemsCampanhaCredito = [];
        
        campanhaCreditoRequest.valor = formaterDecimalBRServer(campanhaCreditoRequest.valor);
        campanhaCreditoRequest.desconto = formaterDecimalBRServer(campanhaCreditoRequest.desconto);
        campanhaCreditoRequest.dataValidade = formatDateToServer(campanhaCreditoRequest.dataValidade);

        if (campanhaCreditoRequest.listItemsCampanhaCredito !== null && campanhaCreditoRequest.listItemsCampanhaCredito !== undefined) {
            campanhaCreditoRequest.listItemsCampanhaCredito.forEach(itemCampanhaCredito => {
                if (itemCampanhaCredito && itemCampanhaCredito.distribuidorSelected) {
                    newListItemsCampanhaCredito.push({
                        id: itemCampanhaCredito.id,
                        idDistribuidor: itemCampanhaCredito.distribuidorSelected.id,
                    })

                    campanhaCreditoRequest.listItemsCampanhaCredito = newListItemsCampanhaCredito;
                }
            })
        }
    }

    return campanhaCreditoRequest;
}
