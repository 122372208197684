export const LIST_RELEASES = [
    { 
        versao: "2.2.1", 
        data: "23/09/2024", 
        listDescricoes: [
            "Histórico de produtos",
            "Mostrar ultimas atualizações feitas",
        ]
    },
    { 
        versao: "2.2.2", 
        data: "27/09/2024", 
        listDescricoes: [
            "Tratativa na flag de desconto de campanha",
            "Correção na validação de compra mínima",
        ]
    },
    { 
        versao: "2.2.3", 
        data: "09/10/2024", 
        listDescricoes: [
            "Separação do custo base",
            "Atualização do prazo entrega na integração com o totvs",
            "Correção da multiplicação por 100 na edição da cotação",
            "Validação de margem minima no carrinho para não travar"
        ]
    },
    { 
        versao: "2.2.4", 
        data: "10/10/2024", 
        listDescricoes: [
            "Correções no calculo do custo base",
        ]
    },
    { 
        versao: "2.2.5", 
        data: "16/10/2024", 
        listDescricoes: [
            "Automatização da natureza",
            "Exportação dos produtos de código fechado",
        ]
    },
    { 
        versao: "2.2.9", 
        data: "23/10/2024", 
        listDescricoes: [
            "Importação de produto código fechado",
            "Ajuste no calculo do icp quando o desconto for zero",
            "Correção no botão para aplicar a margem e prazo de entrega a todos os parametros",
        ]
    },
    { 
        versao: "2.3.1", 
        data: "24/10/2024", 
        listDescricoes: [
            "Ajustes na importação de produto código fechado",
        ]
    },
    { 
        versao: "2.3.2", 
        data: "25/10/2024", 
        listDescricoes: [
            "Ajustes no calculo base dos parametros",
        ]
    },
    { 
        versao: "2.3.3", 
        data: "28/10/2024", 
        listDescricoes: [
            "Correção na consulta de pedido para integração",
            "Correção no calculo do icp",
        ]
    },
    { 
        versao: "2.3.4", 
        data: "29/10/2024", 
        listDescricoes: [
            "Exportação dos produtos de código parametrizável",
            "Log do Status e Indisponibilidade do produto",
            "Tratativa campanha de crédito"
        ]
    },
    { 
        versao: "2.3.5", 
        data: "06/11/2024", 
        listDescricoes: [
            "Importação dos produtos de código parametrizável",
            "Considerar o desligamento manual da chave de campanha",
            "Acrescentado transportadora e cnpj do cliente na página do cliente",
            "Informação do estoque na consulta do produto",
            "Informativo de consulta de estoque no PDF da cotação",
        ]
    },
    { 
        versao: "2.3.6", 
        data: "06/11/2024", 
        listDescricoes: [
            "Correção da validação de compra mínima",
            "Correção do prazo de entrega para cotações de entrega turbo",
            "Correção do prazo de entrega dos itens com peça em estoque e o prazo é editado",
        ]
    },
    { 
        versao: "2.3.7", 
        data: "28/11/2024", 
        listDescricoes: [
            "Adicionado dados de contato no carrinho e edição da cotação",
            "Considerar prazo de entrega dos parametros quando maior que o prazo padrão",
            "Notificação de alteração de prazo de entrega",
        ]
    },
    { 
        versao: "2.3.8", 
        data: "28/11/2024", 
        listDescricoes: [
            "Adicionado endpoint para testes do Totvs",
            "Ajuste no envio de e-mail de atualização do prazo de entrega",
        ]
    },
    { 
        versao: "2.3.9", 
        data: "10/12/2024", 
        listDescricoes: [
            "Acrescentado informação do ST",
            "Validação de cadastro do código do produto duplicado",
        ]
    },
    { 
        versao: "2.4.0", 
        data: "13/12/2024", 
        listDescricoes: [
            "Acrescentado informação do ST do PDF",
            "Adicionado tratamento para consulta de saldo de campanha",
            "Adicionado email e telefone do vendedor no pdf da cotação",
        ]
    },
]