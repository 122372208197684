
import Vue from 'vue'
import { perfilFormatServer } from "./perfilFormatServer";

const getDefaultState = () => {
    return {
        perfil: {
            id: 0,
            nome: "",
            criacaoPedidos: "",
            editarValidarPedidoOutroUsuario: "",
            acompanharPedido: "",
            acompanharPedidoOutroUsuario: "",
            status: ""
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async List() {

        try {
            const result = await Vue.prototype.$spTechApi.get("usuario/perfil/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listPerfil = result.data.result;

                return listPerfil;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar perfis: [${ex}]`
            };
        }
    },

    async GetById(_, id) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`usuario/perfil/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Erro ao obter perfil`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Erro ao obter perfil: [${ex}]`
            };
        }
    },

    async CreateUpdate(_, perfilRequest) {

        try {
            let perfilRequestFormated = perfilFormatServer(JSON.parse(JSON.stringify(perfilRequest)));

            const result = await Vue.prototype.$spTechApi.post(`usuario/perfil/create-update`, perfilRequestFormated,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Registro atualizado com sucesso!";

                if (perfilRequest.id === 0) {
                    msg = "Registro criado com sucesso!";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao criar/atualizar o registro!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Erro ao criar/atualizar o registro: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Erro ao criar/atualizar o registro: [${error}]`
                };
            }
        }
    },

    async Delete(_, id) {

        try {
            const result = await Vue.prototype.$spTechApi.delete(`usuario/perfil/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                return {
                    success: true,
                    message: "Registro excluído com sucesso!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Erro ao excluir registro: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Erro ao excluir registro: [${error}]`
                };
            }
        }
    },

    async AlterarStatus(_, { id, statusId }) {

        try {
            const result = await Vue.prototype.$spTechApi.put(`usuario/perfil/update-status/${id}/${statusId}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Perfil atualizado com sucesso!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao atualizar o status!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao atualizar o status: [${ex}]`
            };
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.perfil, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.perfil;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
