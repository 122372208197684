<template>
    <div class="text-center">
		<v-dialog
			v-model="showDialogReleases"
			transition="dialog-top-transition"
			persistent
			width="700"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
					Atualizações
				</v-card-title>

				<v-card-text style="margin-top: 20px; text-align: center;">
						
					<v-row>
						<v-col 
							cols="12"
							lg="12"
							md="12"
							sm="12"
						>	
							<table style="width: 100%; border: none;" cellspacing="10">
								<tr>
									<td class="align-right font15"><strong>Versão:</strong></td>
									<td class="align-left font15">{{ versao }}</td>
								</tr>
								<tr>
									<td class="align-right font15"><strong>Data:</strong></td>
									<td class="align-left font15">{{ data }}</td>
								</tr>
								<tr>
									<td class="align-right font15" valign="top"><strong>Atualizações:</strong></td>
									<td class="align-left font15" valign="top"><div v-html="descricao"></div></td>
								</tr>								
							</table>
						</v-col>
					</v-row>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 btn-plus botaoDispensar"
							outlined
							@click="fechar()"
						>
							Fechar
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
	import { LIST_RELEASES } from "@/utilities/Releases";

    export default {

        mixins: [Helpers],

        props: {
			showDialogReleases: {
				default: false
			},
		},
		
        data: () => ({
			LIST_RELEASES,

			versao: '',
			data: '',
			descricao: ''
		}),

        methods: {
            fechar() {
				this.$emit('update:showDialogReleases', false)
            },
        },

		created() {
			const ultimaAtualizacao = this.LIST_RELEASES[this.LIST_RELEASES.length - 1];
			this.versao = ultimaAtualizacao.versao;
			this.data = ultimaAtualizacao.data;
			this.descricao = '';

			ultimaAtualizacao.listDescricoes.forEach(itemDescricao => {
				this.descricao += `<p style="margin-bottom: 5px !important">- ${itemDescricao}</p>`
			});
		}
    };
</script>

<style scoped>

	.font15 {
		font-size: 15px !important;
	}

	.align-left {
		text-align: left;
	}

	.align-right {
		text-align: right;
	}
</style>