
import Vue from 'vue'

const actions = {

    async ListNaturezas() {

        try {
            const result = await Vue.prototype.$spTechApi.get("comum/totvs/list-naturezas",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const list = result.data.result;

                return list;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar naturezas: [${ex}]`
            };
        }
    },

    async ListContaContabil() {

        try {
            const result = await Vue.prototype.$spTechApi.get("comum/totvs/list-conta-contabil",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const list = result.data.result;

                return list;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar contas contabeis: [${ex}]`
            };
        }
    },

    async ListCentroCusto() {

        try {
            const result = await Vue.prototype.$spTechApi.get("comum/totvs/list-centro-custo",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const list = result.data.result;

                return list;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar centros de custo: [${ex}]`
            };
        }
    },

    async ListArmazem() {

        try {
            const result = await Vue.prototype.$spTechApi.get("comum/totvs/list-armazem",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const list = result.data.result;

                return list;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar armazéns: [${ex}]`
            };
        }
    },

    async ListGrupoProduto() {

        try {
            const result = await Vue.prototype.$spTechApi.get("comum/totvs/list-grupo-produto",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const list = result.data.result;

                return list;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar grupo de produto: [${ex}]`
            };
        }
    },

    async ListFamiliaProduto() {

        try {
            const result = await Vue.prototype.$spTechApi.get("comum/totvs/list-familia-produto",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const list = result.data.result;

                return list;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar familia de produto: [${ex}]`
            };
        }
    },

    async ListTiposDeProduto() {

        try {
            const result = await Vue.prototype.$spTechApi.get("comum/totvs/list-tipos-de-produto",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const list = result.data.result;

                return list;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar tipos de produto: [${ex}]`
            };
        }
    },

    async ListTiposDoProduto() {

        try {
            const result = await Vue.prototype.$spTechApi.get("comum/totvs/list-tipos-do-produto",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const list = result.data.result;

                return list;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar tipos do produto: [${ex}]`
            };
        }
    },

    async ListGrupoTributario() {

        try {
            const result = await Vue.prototype.$spTechApi.get("comum/totvs/list-grupo-tributario",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const list = result.data.result;

                return list;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar grupo tributário: [${ex}]`
            };
        }
    },

    async ListEmpresaOrigem() {

        try {
            const result = await Vue.prototype.$spTechApi.get("comum/totvs/list-empresa-origem",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const list = result.data.result;

                return list;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar empresa origem: [${ex}]`
            };
        }
    },

    async ListFornecedores() {

        try {
            const result = await Vue.prototype.$spTechApi.get("comum/totvs/list-fornecedores",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const list = result.data.result;

                return list;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar os fornecedores: [${ex}]`
            };
        }
    },
}

export default {
    namespaced: true,
    actions
}
