function setCache(chave, valor, tempoExpiracaoEmSegundos) {
    const agora = new Date().getTime();
    const expiracao = agora + tempoExpiracaoEmSegundos * 1000; // Tempo de expiração em milissegundos
    const itemCache = { valor: valor, expiracao: expiracao };
    localStorage.setItem(chave, JSON.stringify(itemCache));
}

function getCache(chave) {
    const itemCache = localStorage.getItem(chave);
    if (itemCache) {
        const parsedItem = JSON.parse(itemCache);
        if (parsedItem.expiracao > new Date().getTime()) {
            return parsedItem.valor;
        } else {
            // Remover do cache se estiver expirado
            localStorage.removeItem(chave);
        }
    }
    return null;
}

export {
	setCache,
    getCache
} 