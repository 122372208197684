import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

import pt from './pt.json';
import es from './es.json';

const messages = {
    pt,
    es
};

const userLocale = navigator.language || navigator.userLanguage;
// const defaultLanguage = 'es'
const defaultLanguage = process.env.VUE_APP_DEFAULT_LANGUAGE

const i18n = new VueI18n({
    locale: userLocale.split('-')[0] || defaultLanguage,
    fallbackLocale: defaultLanguage,
    messages,
});

export default i18n;
