<template>

    <v-navigation-drawer 
        style="width: 255px !important;"
        v-model="$store.state.menuSide" 
        app
    >
        <v-list>
            <v-list-item style="display: block; text-align: center;">
                <a href="/">
                    <v-img :src="require('@/assets/images/logo.png')" max-height="185" max-width="205" />
                </a>
            </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list dense v-if="userLoggedGetters.listGrupoMenu !== null">
            
            <v-list-group
                v-for="itemGrupoMenu in userLoggedGetters.listGrupoMenu.filter(grp => grp.visivel == 1)" :key="itemGrupoMenu.idGroupMenu"
                :prepend-icon="itemGrupoMenu.mdiIcon"
                sub-group
            >
                <template v-slot:activator>
                    <v-list-item-title>{{itemGrupoMenu.descricao}}</v-list-item-title>
                </template>

                <v-list-item
                    v-for="(itemMenu, index) in itemGrupoMenu.listMenu.filter(mnu => mnu.showInMenu === true)" 
                    :key="index" 
                    link 
                    :to="`${itemMenu.path}`"
                >

                    <v-list-item-icon>
                        <v-icon v-text="itemMenu.mdiIcon"></v-icon>
                    </v-list-item-icon>

                    <v-list-item-title v-text="itemMenu.descricao"></v-list-item-title>

                </v-list-item>

            </v-list-group>

            <v-list-item
                v-for="(itemMenu, index) in listMenuCliente" 
                :key="index" 
                link 
                :to="`${itemMenu.path}`"
            >

                <v-list-item-icon>
                    <v-icon v-text="itemMenu.mdiIcon"></v-icon>
                </v-list-item-icon>

                <v-list-item-title v-text="itemMenu.descricao"></v-list-item-title>

            </v-list-item>


        </v-list>

    </v-navigation-drawer>

</template>

<script>
    
    import { mapGetters } from 'vuex';

    export default {

        data: () => ({
            listMenuCliente: []
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' })
        },

        created() {
            this.$store.dispatch("pedidoModule/ConsultaStatusPedido");

            let listMenu = this.userLoggedGetters.listGrupoMenu.filter(grp => grp.visivel == 0);

            if (listMenu != null && listMenu != undefined && listMenu.length > 0) {
                this.listMenuCliente = listMenu[0].listMenu.filter(mnu => mnu.showInMenu === true);

                this.$router.push({ path: `${this.listMenuCliente[0].path}` });
            }

            const interval = 1000 * 60 * 30; //30 minutos

            let intervalConsultaBackEnd = setInterval( async () => {
                
                let token = localStorage.getItem('token');

                if (token == null || token == undefined) {
                    clearInterval(intervalConsultaBackEnd)
                }

                if (token != null && token != undefined && token != '') {
                
                    //CONSULTAR STATUS DOS PEDIDOS E COTAÇÕES EXPIRADAS
                    this.$store.dispatch("pedidoModule/ConsultaStatusPedido");
                    this.$store.dispatch("orcamentoModule/VerificarCotacoesExpiradas");
                }

            }, interval);
        }
    };

</script>