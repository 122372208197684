import Vue from 'vue';
import Router from 'vue-router';
import paths from './paths';

Vue.use(Router);

const router = new Router({
	base: '/',
	mode: 'history',
	linkActiveClass: 'active',
	routes: paths
});

var nextUrl = null;

router.beforeEach(async (to, from, next) => {

	let app = router.app.$root || { isAuthenticated: false }

	if (app.isAuthenticated) {

		next();

	} 
	else if (to.matched.some(record => record.meta.requiresAuth)) {

		if (nextUrl === null)
			nextUrl = to;

		next({ name: "Validate", params: { nextUrl: to.fullPath } });
	} 
	else {
		next()
	}
});

export default router;
