
import Vue from 'vue'
import { showToast } from '@/utilities/Utils'
import { setCache, getCache } from '@/utilities/Cache'

const getDefaultState = () => {
    return {
        empresa: {
            id: 0,
            cnpj: "",
            razaoSocial: "",
            status: ""
        }
    }
};

const TEMPO_CACHE = 60 * 60;

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async List() {

        try {
            const result = await Vue.prototype.$spTechApi.get("empresa/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listEmpresa = result.data.result;

                return listEmpresa;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar empresas: [${ex}]`
            };
        }
    },

    async ListDistribuidor() {

        try {

            // Obtendo dados do cache
            const listDistribuidorCache = getCache('listDistribuidorCache');

            if (listDistribuidorCache) {
                return listDistribuidorCache;
            }
            else {

                const result = await Vue.prototype.$spTechApi.get("empresa/list-distribuidor",
                {
                    headers: {
                        "content-type": "application/json"
                    }
                });

                if (result.data) {
                    let listDistribuidor = result.data.result;

                    setCache('listDistribuidorCache', listDistribuidor, TEMPO_CACHE);

                    return listDistribuidor;
                }
                else {
                    return [];
                }
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                showToast("error", "Erro!", error.response.data);
            }
            else {
                showToast("error", "Erro!", error);
            }
        }
    },

    async ListClientes() {

        try {
            const result = await Vue.prototype.$spTechApi.get("empresa/list-clientes",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listClientes = result.data.result;

                return listClientes;
            }
            else {
                return [];
            }
        }
        catch(error) {


            if (error.response && error.response.status === 400) {
                showToast("error", "Erro!", error.response.data);
            }
            else {
                showToast("error", "Erro!", error);
            }
        }
    },

    async ListKeyAccount() {

        try {
            // Obtendo dados do cache
            const listKeyAccountCache = getCache('listKeyAccountCache');

            if (listKeyAccountCache) {
                return listKeyAccountCache;
            }
            else {

                const result = await Vue.prototype.$spTechApi.get("empresa/list-key-account",
                {
                    headers: {
                        "content-type": "application/json"
                    }
                });

                if (result.data) {
                    let listKeyAccount = result.data.result;

                    setCache('listKeyAccountCache', listKeyAccount, TEMPO_CACHE);

                    return listKeyAccount;
                }
                else {
                    return [];
                }
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                showToast("error", "Erro!", error.response.data);
            }
            else {
                showToast("error", "Erro!", error);
            }
        }
    },

    async GetById(_, id) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`empresa/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Erro ao obter empresa`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Erro ao obter empresa: [${ex}]`
            };
        }
    },

    async CreateUpdate(_, empresaRequest) {

        try {

            const result = await Vue.prototype.$spTechApi.post(`empresa/create-update`, empresaRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Registro atualizado com sucesso!";

                if (empresaRequest.id === 0) {
                    msg = "Registro criado com sucesso!";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao criar/atualizar o registro!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Erro ao criar/atualizar o registro: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Erro ao criar/atualizar o registro: [${error}]`
                };
            }
        }
    },

    async CreateUpdateCliente(_, clienteRequest) {

        try {

            const result = await Vue.prototype.$spTechApi.post(`empresa/create-update-cliente`, clienteRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                actions.ListClientes();

                return {
                    success: true,
                    message: "Cliente criado com sucesso!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao cadastrar novo cliente!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Erro ao cadastrar novo cliente: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Erro ao cadastrar novo cliente: [${error}]`
                };
            }
        }
    },

    async AlterarStatus(_, { id, statusId }) {

        try {
            const result = await Vue.prototype.$spTechApi.put(`empresa/update-status/${id}/${statusId}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Empresa atualizado com sucesso!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao atualizar o status!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao atualizar o status: [${ex}]`
            };
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.empresa, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.empresa;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
