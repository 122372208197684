import { formaterDecimalBRServer } from '@/utilities/Utils';

export function campanhaEstoqueFormatServer(campanhaEstoqueRequest) {

    if (campanhaEstoqueRequest != null) {

        let newListItemsCampanhaEstoque = [];
        
        campanhaEstoqueRequest.valor = formaterDecimalBRServer(campanhaEstoqueRequest.valor)
        campanhaEstoqueRequest.desconto = formaterDecimalBRServer(campanhaEstoqueRequest.desconto)

        if (campanhaEstoqueRequest.listItemsCampanhaEstoque !== null && campanhaEstoqueRequest.listItemsCampanhaEstoque !== undefined) {
            campanhaEstoqueRequest.listItemsCampanhaEstoque.forEach(itemCampanhaEstoque => {
                if (itemCampanhaEstoque && itemCampanhaEstoque.distribuidorSelected) {
                    newListItemsCampanhaEstoque.push({
                        id: itemCampanhaEstoque.id,
                        idDistribuidor: itemCampanhaEstoque.distribuidorSelected.id,
                    })

                    campanhaEstoqueRequest.listItemsCampanhaEstoque = newListItemsCampanhaEstoque;
                }
            })
        }
    }

    return campanhaEstoqueRequest;
}
