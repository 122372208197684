import { formaterDecimalBRServer, formaterDecimalBR } from '@/utilities/Utils';

export function configAnaliseCreditoFormatServer(configAnaliseCredito) {

    configAnaliseCredito.valorInicial = formaterDecimalBRServer(configAnaliseCredito.valorInicial);
    configAnaliseCredito.valorFinal = formaterDecimalBRServer(configAnaliseCredito.valorFinal);

    if (configAnaliseCredito.bloqueia == null) {
        configAnaliseCredito.bloqueia = 0;
    }
    
    return configAnaliseCredito;
}

export function configAnaliseCreditoFormatFront(configAnaliseCredito) {

    configAnaliseCredito.valorInicial = formaterDecimalBR(configAnaliseCredito.valorInicial);
    configAnaliseCredito.valorFinal = formaterDecimalBR(configAnaliseCredito.valorFinal);
    
    return configAnaliseCredito;
}