
import Vue from 'vue'

const getDefaultState = () => {
    return {
        usuario: {
          id: 0,
          nome: "",
          email: "",
        },
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async GetById( { commit }, idUsuario) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`usuario/${idUsuario}`,
            {
                headers: {
                "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                let usuarioResponse = result.data;

                commit('saveState', usuarioResponse);

                return usuarioResponse;
            }
            else {
                return false
            }
        }
        catch(ex) {
            console.log("Erro ao obter o usuário", ex);
            return false;
        }
    },

    async AtualizarPerfil( _, requestPerfil) {

        try {
            const result = await Vue.prototype.$spTechApi.put(`usuario/atualizar-perfil/${requestPerfil.idUsuario}`, requestPerfil,
            {
                headers: {
                "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return {
                    success: true,
                    message: `Dados do perfil atualizados com sucesso!`
                };
            }
            else {
                return {
                    success: false,
                    message: `Erro ao atualizar as dados do perfil. Por favor, verifique os valores informados!`
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Erro ao atualizar dados do perfil: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Erro ao atualizar dados do perfil: [${error}]`
                };
            }
        }
    },

    async List() {

        try {
            const result = await Vue.prototype.$spTechApi.get("usuario/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listUsuario = result.data.result;
                return listUsuario;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar os usuários: [${ex}]`
            };
        }
    },

    async CreateUpdate(_, userRequest) {

        try {

            const result = await Vue.prototype.$spTechApi.post(`usuario/create-update`, userRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Registro atualizado com sucesso!";

                if (userRequest.id === 0) {
                    msg = "Registro criado com sucesso!";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao criar/atualizar o registro!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Erro ao criar/atualizar o registro: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Erro ao criar/atualizar o registro: [${error}]`
                };
            }
        }
    },

    async AlterarStatus(_, { id, statusId }) {

        try {
            const result = await Vue.prototype.$spTechApi.put(`usuario/atualizar-status/${id}/${statusId}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Status atualizado com sucesso!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao atualizar o status!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao atualizar o status: [${ex}]`
            };
        }
    },

    async AlterarSenha( _, passwordRequest) {

        try {
            const result = await Vue.prototype.$spTechApi.put(`usuario/alterar-senha/${passwordRequest.idUsuario}`, passwordRequest,
            {
                headers: {
                "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return {
                    success: true,
                    message: `Senha alterada com sucesso!`
                };
            }
            else {
                return {
                    success: false,
                    message: `Erro ao alterar senha. Por favor, tente novamente!`
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Erro ao alterar senha: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Erro ao alterar senha: [${error}]`
                };
            }
        }
    },
    
    async ResetSenha( _, newPasswordRequest) {

        try {
            const result = await Vue.prototype.$spTechApi.post(`usuario/reset-senha`, newPasswordRequest,
            {
                headers: {
                "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return {
                    success: true,
                    message: `Reset de senha efetuado com sucesso!`
                };
            }
            else {
                return {
                    success: false,
                    message: `Erro ao resetar senha. Por favor, tente novamente!`
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Erro ao resetar senha: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Erro ao resetar senha: [${error}]`
                };
            }
        }
    },

    async ResetUsuarioSenha( _, ResetUsuarioSenhaRequest) {

        try {
            const result = await Vue.prototype.$spTechApi.put(`usuario/reset-usuario-senha/${ResetUsuarioSenhaRequest.idUsuario}`, ResetUsuarioSenhaRequest,
            {
                headers: {
                "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return {
                    success: true,
                    message: `Senha do usuário resetada com sucesso!`
                };
            }
            else {
                return {
                    success: false,
                    message: `Erro ao resetar senha do usuário. Por favor, tente novamente!`
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Erro ao resetar senha do usuário: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Erro ao resetar senha do usuário: [${error}]`
                };
            }
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.usuario, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.usuario;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
