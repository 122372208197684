import { formaterDecimalBRServer } from '@/utilities/Utils';

export function campanhaOuroPrataFormatServer(campanhaOuroPrataRequest) {

    if (campanhaOuroPrataRequest != null) {

        let newListItemsCampanhaOuroPrata = [];
        
        campanhaOuroPrataRequest.valor = formaterDecimalBRServer(campanhaOuroPrataRequest.valor)
        campanhaOuroPrataRequest.desconto = formaterDecimalBRServer(campanhaOuroPrataRequest.desconto)

        if (campanhaOuroPrataRequest.listItemsCampanhaOuroPrata !== null && campanhaOuroPrataRequest.listItemsCampanhaOuroPrata !== undefined) {
            campanhaOuroPrataRequest.listItemsCampanhaOuroPrata.forEach(itemCampanhaOuroPrata => {
                if (itemCampanhaOuroPrata && itemCampanhaOuroPrata.distribuidorSelected) {
                    newListItemsCampanhaOuroPrata.push({
                        id: itemCampanhaOuroPrata.id,
                        idDistribuidor: itemCampanhaOuroPrata.distribuidorSelected.id,
                    })

                    campanhaOuroPrataRequest.listItemsCampanhaOuroPrata = newListItemsCampanhaOuroPrata;
                }
            })
        }
    }

    return campanhaOuroPrataRequest;
}
