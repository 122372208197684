
import Vue from 'vue'

import { configAnaliseCreditoFormatServer, configAnaliseCreditoFormatFront } from "./configAnaliseCreditoFormatServer";
import { showToast } from '@/utilities/Utils'
import { setCache, getCache } from '@/utilities/Cache'

const getDefaultState = () => {
    return {
        configAnaliseCredito: {
            id: 0
        }
    }
};

const TEMPO_CACHE = 60 * 60;

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async GetCreditoCliente(_, idCliente) {

        try {

            const cacheKey = `analiseCreditoCliente-${idCliente}`

            // Obtendo dados do cache
            const analiseCreditoCliente = getCache(cacheKey);

            if (analiseCreditoCliente) {
                return analiseCreditoCliente;
            }
            else {

                const result = await Vue.prototype.$spTechApi.get(`analise-credito/get-analise-credito-by-id/${idCliente}`,
                {
                    headers: {
                        "content-type": "application/json"
                    }
                });

                if (result.data) {
                    let analiseCreditoResult = result.data.result;

                    setCache(cacheKey, analiseCreditoResult, TEMPO_CACHE);

                    return analiseCreditoResult;
                }
                else {
                    return {};
                }
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                showToast("error", "Erro!", error.response.data);
            }
            else {
                showToast("error", "Erro!", error);
            }
        }
    },

    async List() {

        try {
            const result = await Vue.prototype.$spTechApi.get("analise-credito/config/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listAnaliseCredito = result.data.result;

                return listAnaliseCredito;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar configuração da análise de crédito: [${ex}]`
            };
        }
    },

    async GetById(_, id) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`analise-credito/config/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Erro ao obter configuração da análise de crédito`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Erro ao obter configuração da análise de crédito: [${ex}]`
            };
        }
    },

    async CreateUpdate(_, configAnaliseCreditoRequest) {

        try {
            let configAnaliseCreditoRequestFormatted = configAnaliseCreditoFormatServer(configAnaliseCreditoRequest);
            const result = await Vue.prototype.$spTechApi.post(`analise-credito/config/create-update`, configAnaliseCreditoRequestFormatted,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Registro atualizado com sucesso!";

                if (configAnaliseCreditoRequest.id === 0) {
                    msg = "Registro criado com sucesso!";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao criar/atualizar o registro!"
                };
            }
        }
        catch(error) {

            configAnaliseCreditoFormatFront(configAnaliseCreditoRequest);

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Erro ao criar/atualizar o registro: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Erro ao criar/atualizar o registro: [${error}]`
                };
            }
        }
    }
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.marca, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.marca;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
