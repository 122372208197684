
const listRoutes = [
    {
        path: '*', 
        meta: { requiresAuth: false },
        redirect: { path: '/login' }
    },
    {
        path: '/404',
        meta: { requiresAuth: false },
        name: 'NotFound',
        component: () => import( /* webpackChunkName: "NotFound" */ `@/views/Login/NotFound.vue` )
    },
    {
        path: '/403',
        meta: { requiresAuth: false },
        name: 'AccessDenied',
        component: () => import(/* webpackChunkName: "Deny" */ `@/views/Login/Deny.vue` )
    },
    {
        path: '/500',
        name: 'ServerError',
        meta: { requiresAuth: false },
        component: () => import(/* webpackChunkName: "Error" */ `@/views/Login/Error.vue` )
    },
    
    {
        path: '/',
        meta: { requiresAuth: true },
        component: () => import(/*webpackChunkName: "Dashboard" */ '@/views/Main/Dashboard'),
    },

    { 
        path: '/login',
        name: 'login',
        props: false,
        component: () => import(/* webpackChunkName: "login" */ '@/views/Login/Login.vue') 
    },

    { 
        path: '/logout',
        name: 'logout',
        props: false,
        component: () => import(/* webpackChunkName: "logout" */ '@/views/Login/Logout.vue') 
    },

    { 
        path: '/Validate/:nextUrl?',
        name: 'Validate',
        props: true,
        component: () => import(/*webpackChunkName: "Validate" */ '@/views/Login/Validate') 
    },
    
    {
        path: '/usuario/minhasInformacoes',
        name: 'minhasInformacoes',
        meta: { requiresAuth: true },
        component: () => import(/*webpackChunkName: "MinhasInformacoes" */ '@/views/Usuario/MinhasInformacoes'),
    },
    
    {
        path: '/usuario/alterarSenha',
        name: 'alterarSenha',
        meta: { requiresAuth: true },
        component: () => import(/*webpackChunkName: "AlterarSenha" */ '@/views/Usuario/AlterarSenha'),
    },
    
    {
        path: '/usuario/recuperarSenha/:token',
        name: 'recuperarSenha',
        props: true,
        component: () => import(/*webpackChunkName: "RecuperarSenha" */ '@/views/Usuario/RecuperarSenha'),
    },
    
    {
        path: '/pedido/trackerPage/:id',
        name: 'trackerPage',
        props: true,
        component: () => import(/*webpackChunkName: "TrackerPage" */ '@/views/Pedido/TrackerPage'),
    },
]

let listRoutesFromLocalStorage = localStorage.getItem('listRouters')

if (listRoutesFromLocalStorage !== null && listRoutesFromLocalStorage !== undefined) {
    let listRoutesJSON = JSON.parse(listRoutesFromLocalStorage)

    listRoutesJSON.forEach(itemMenu => {
        
        listRoutes.push({
            path: itemMenu.path,
            name: itemMenu.name,
            props: itemMenu.props,
            meta: { requiresAuth: itemMenu.requiresAuth },
            component: () => import( /*webpackChunkName: "application" */ `@/views/${itemMenu.urlFile}`),
        })
    });
}

export default listRoutes;
