
import Vue from 'vue'

const getDefaultState = () => {
    return {
        tipoFrete: {
            id: 0,
            descricao: ""
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async List() {

        try {
            const result = await Vue.prototype.$spTechApi.get("comum/tipoFrete/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listTipoFrete = result.data.result;

                return listTipoFrete;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar tipos de frete: [${ex}]`
            };
        }
    },

    async GetById(_, id) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`comum/tipoFrete/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Erro ao obter tipo de frete`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Erro ao obter tipo de frete: [${ex}]`
            };
        }
    },

    async CreateUpdate(_, tipoFreteRequest) {

        try {
            const result = await Vue.prototype.$spTechApi.post(`comum/tipoFrete/create-update`, tipoFreteRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Registro atualizado com sucesso!";

                if (tipoFreteRequest.id === 0) {
                    msg = "Registro criado com sucesso!";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao criar/atualizar o registro!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Erro ao criar/atualizar o registro: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Erro ao criar/atualizar o registro: [${error}]`
                };
            }
        }
    },

    async Delete(_, id) {

        try {
            const result = await Vue.prototype.$spTechApi.delete(`comum/tipoFrete/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                return {
                    success: true,
                    message: "Registro excluído com sucesso!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Erro ao excluir registro: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Erro ao excluir registro: [${error}]`
                };
            }
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.tipoFrete, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.tipoFrete;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
