const getDefaultState = () => {
    return {
        listCarrinho: [],

        carrinho: {
            reload: false
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    List() {

        try {
            
            let carrinhoStorageJSON = localStorage.getItem('carrinho');
            let carrinhoStorage = null;

            if (carrinhoStorageJSON != null && carrinhoStorageJSON != undefined) {
                carrinhoStorage = JSON.parse(carrinhoStorageJSON);
            }

            if (carrinhoStorage != null && carrinhoStorage != undefined) {
                return carrinhoStorage;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar carrinho de compra: [${ex}]`,
                data: [],
            };
        }
    },

    CreateUpdate(_, { itemProduto, forcarAdicionarProduto = true }) {

        try {
            let response = actions.List();
            
            let listCarrinho = response;

            let itemProdutoExistente = listCarrinho.filter(car => car.codigoProduto == itemProduto.codigoProduto)[0];

            if (itemProdutoExistente != null && 
                itemProdutoExistente != undefined && 
                forcarAdicionarProduto == false) {
                itemProdutoExistente.qtde = itemProduto.qtde;
            }
            else {
                listCarrinho.push(itemProduto)
            }

            // let itemProdutoExistente = listCarrinho.filter(car => car.codigoProduto == itemProduto.codigoProduto)[0];

            // if (itemProdutoExistente != null && itemProdutoExistente != undefined) {

            //     if (aumentarQtdeCasoProdutoExiste) {
            //         itemProdutoExistente.qtde += 1;
            //     }
            //     else {
            //         itemProdutoExistente.qtde = itemProduto.qtde;
            //     }
            // }
            // else {
            //     listCarrinho.push(itemProduto)
            // }

            if (listCarrinho != null && listCarrinho != undefined) {
                localStorage.setItem('carrinho', JSON.stringify(listCarrinho));

                return {
                    success: true,
                    message: `Produto adicionado com sucesso!`
                }
            }
        }
        catch(error) {

            if (error) {
                return {
                    success: false,
                    message: `${error.message}`
                };
            }
        }
    },

    ExcluirItem(_, itemProduto) {

        try {            
            let response = actions.List();
            
            let listCarrinho = response;
            let listCarrinhoAux = [...listCarrinho];

            let index = 0;

            listCarrinhoAux.forEach(item => {
                if (item.codigoProduto == itemProduto.codigoProduto) {
                    listCarrinho.splice(index, 1);
                }
                index++;
            });

            if (listCarrinho != null && listCarrinho != undefined) {
                localStorage.setItem('carrinho', JSON.stringify(listCarrinho));

                return {
                    success: true,
                    message: `Item excluído com sucesso!`
                }
            }
        }
        catch(error) {

            if (error) {
                return {
                    success: false,
                    message: `${error.message}`
                };
            }
        }
    },

    LimparCarrinho() {

        try {            
            let listCarrinho = [];
            localStorage.setItem('carrinho', JSON.stringify(listCarrinho));

            return {
                success: true,
                message: `Carrinho está vazio!`
            }
        }
        catch(error) {

            if (error) {
                return {
                    success: false,
                    message: `${error.message}`
                };
            }
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.carrinho, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
        actions.CreateUpdate(null, state)
    }
}

const getters = {

    searchState: () => {
        let response = actions.List();
        let listCarrinho = response;
        return listCarrinho
    },

    carrinhoState: state => {
        return state.carrinho;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
