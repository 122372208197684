import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VTooltip from 'v-tooltip'
import VuejsDialog from 'vuejs-dialog'
import store from './store/store'
import router from './router/index'
import i18n from './locales/_i18n';

import './plugins/v-dialog';
import "@mdi/font/css/materialdesignicons.css"
import './assets/scss/styles.scss'
import "material-design-icons/iconfont/material-icons.css"
import './plugins/vue-izitoast'
import './plugins/vue-the-mask';
import './directives/v-formatMoney';

import './services/apiService';

Vue.config.productionTip = false

Vue.use(VuejsDialog);

Vue.use(VTooltip, {
	defaultDelay: 150
})

new Vue({
	i18n,
	vuetify,
	store,
	router,
	render: h => h(App)
}).$mount('#app')
