
import Vue from 'vue'
import { showToast } from '@/utilities/Utils'

import { orcamentoFormatServer } from "./orcamentoFormatServer";

const getDefaultState = () => {
    return {
        orcamento: {
            id: 0,
            dataCadastro: "",
            idUsuarioCadastro: 0,
            status: 1,
            idDistribuidor: 0,
            valorTotalBruto: 0,
            valorTotal: 0
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async List(_, mostrarRevisao) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`orcamento/list/${mostrarRevisao}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listOrcamento = result.data.result;

                return listOrcamento;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar orcamentos: [${ex}]`
            };
        }
    },

    async GetById(_, id) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`orcamento/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Erro ao obter cotação`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Erro ao obter cotação: [${ex}]`
            };
        }
    },

    async GetByGuid(_, guid) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`orcamento/get-by-guid/${guid}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Erro ao obter cotação`
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                showToast("error", "Erro!", error.response.data);
            }
            else {
                showToast("error", "Erro!", error);
            }
        }
    },

    async CreateUpdate(_, orcamentoRequest) {

        try {
            let orcamentoRequestFormatted = orcamentoFormatServer(orcamentoRequest);

            const result = await Vue.prototype.$spTechApi.post(`orcamento/create-update`, orcamentoRequestFormatted,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                let msg = "Registro atualizado com sucesso!";

                if (orcamentoRequest.id === 0) {
                    msg = "Registro criado com sucesso!";
                }

                return {
                    success: true,
                    idOrcamento: result.data.result,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao criar/atualizar o registro!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Erro ao criar/atualizar o registro: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Erro ao criar/atualizar o registro: [${error}]`
                };
            }
        }
    },

    async AlterarStatus(_, { id, statusId }) {

        try {
            const result = await Vue.prototype.$spTechApi.put(`orcamento/update-status/${id}/${statusId}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Orcamento atualizada com sucesso!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao atualizar o status!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao atualizar o status: [${ex}]`
            };
        }
    },

    async AlterarStatusPelaRevisao(_, { id, statusId }) {

        try {
            const result = await Vue.prototype.$spTechApi.put(`orcamento/update-status-pela-revisao/${id}/${statusId}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Orcamento atualizada com sucesso!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao atualizar o status!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao atualizar o status: [${ex}]`
            };
        }
    },

    async CriarPedido(_, requestPedido) {

        try {

            const result = await Vue.prototype.$spTechApi.post(`orcamento/criar-pedido/`, requestPedido,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                let msg = "Pedido criado com sucesso!";

                return {
                    success: true,
                    idPedido: result.data.result,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao criar o pedido!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Erro ao criar o pedido: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Erro ao criar o pedido: [${error}]`
                };
            }
        }
    },

    async Clonar(_, id) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`orcamento/clonar/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                return {
                    id: result.data.result,
                    success: true,
                    message: "Cotação clonada com sucesso"
                };
            }
            else {
                return {
                    success: false,
                    result: `Erro ao clonar cotação`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Erro ao clonar cotação: [${ex}]`
            };
        }
    },

    async CreateUpdateHistorico(_, orcamentoHistoricoRequest) {

        try {
            const result = await Vue.prototype.$spTechApi.post(`orcamento/create-update-historico`, orcamentoHistoricoRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Registro atualizado com sucesso!";

                if (orcamentoHistoricoRequest.id === 0) {
                    msg = "Registro criado com sucesso!";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao criar/atualizar o registro!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Erro ao criar/atualizar o registro: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Erro ao criar/atualizar o registro: [${error}]`
                };
            }
        }
    },

    async GetHistorico(_, id) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`orcamento/get-historico/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listHistorico = result.data.result;

                return listHistorico;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar histórico: [${ex}]`
            };
        }
    },

    async VerificarCotacoesExpiradas() {

        try {
            Vue.prototype.$spTechApi.put("orcamento/verificar-cotacoes-expiradas",
            {
                headers: {
                    "content-type": "application/json"
                }
            });
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao verificar cotações expiradas: [${ex}]`
            };
        }
    },

    async GetItensCompraMinima(_, id) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`orcamento/get-itens-compra-minima/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listItens = result.data.result;

                return listItens;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar itens: [${ex}]`
            };
        }
    },
    

    async EnviarEmailAnaliseCredito(_, analiseCreditoRequest) {

        try {

            const result = await Vue.prototype.$spTechApi.post(`orcamento/enviar-email-analise-credito`, analiseCreditoRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "E-mail enviado com sucesso";

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao enviar e-mail!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Erro ao enviar e-mail: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Erro ao enviar e-mail: [${error}]`
                };
            }
        }
    },

    async CreateUpdateCondicaoComercial(_, orcamentoCondicaoComercialRequest) {

        try {
            const result = await Vue.prototype.$spTechApi.post(`orcamento/create-update-condicao-comercial`, orcamentoCondicaoComercialRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Registro atualizado com sucesso!";

                if (orcamentoCondicaoComercialRequest.id === 0) {
                    msg = "Registro criado com sucesso!";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao criar/atualizar o registro!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Erro ao criar/atualizar o registro: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Erro ao criar/atualizar o registro: [${error}]`
                };
            }
        }
    },

    async ListCondicaoComercial() {

        try {
            const result = await Vue.prototype.$spTechApi.get(`orcamento/get-condicao-comercial`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const listCondicaoComercial = result.data.result;

                return listCondicaoComercial;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar as condições comerciais: [${ex}]`
            };
        }
    },

    async DeleteCondicaoComercial(_, id) {


        console.log('id', id);
        try {
            const result = await Vue.prototype.$spTechApi.delete(`orcamento/delete-condicao-comercial/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                const msg = "Excluído com sucesso";

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao excluir condição comercial!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao excluir condição comercial: [${ex}]`
            };
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.orcamento, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.orcamento;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
