import Vue from 'vue';
import VueIziToast from 'vue-izitoast';

import 'izitoast/dist/css/iziToast.min.css';

const defaultOptionsObject = {
    position: 'topRight'
};

Vue.use(VueIziToast, defaultOptionsObject);
