import Vue from 'vue'
import axios from 'axios'
import { showToast, hideLoading } from '@/utilities/Utils'

let mostrouErro = false;

const getToken = async (config) => {

    if (Vue.prototype.getRoot !== undefined) {

        const token = localStorage.getItem("token");

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
    }

    return config;
}

const interceptorMessages = (error) => {

    hideLoading();

    if (error && error.message === 'Network Error') {
        if (mostrouErro == false) {
            showToast("error", "Erro!", "Houve uma falha de comunicação com o servidor. Por favor, tente novamente!");
            mostrouErro = true;
        }
    } 
    else {

        const { response: { status } } = error

        if (status != undefined) {
            
            if (status === 500) {
                showToast("error", "Erro!", "Ocorreu um erro inesperado!");
            } 
            
            else if (status === 401 && window.location.pathname !== "/login") {
                localStorage.clear();
                Vue.prototype.getRouter.push({ name:"login" });
            } 
            
            else if (status === 404) {
                showToast("error", "Erro!", "Ação não encontrada!");
            } 
            
            else if (status === 403) {
                showToast("warning", this.$t('aviso'), "Acesso Negado!");
                Vue.prototype.getRouter.push({name:"AccessDenied"});
            }
        } 
        else {
            showToast("error", "Erro!", "Ocorreu um erro inesperado! Tente novamente.");
        }
    }

};

Vue.use({
    install(Vue) {

        /* CONFIGURAÇÃO DA API - SPTECH */
        Vue.prototype.$spTechApi = axios.create({
            timeout: 300000,
            baseURL: process.env.VUE_APP_URL_API
        });

        Vue.prototype.$spTechApi.interceptors.request.use(async config => {
            return await getToken(config);
        },
        error => {
            return Promise.reject(error);
        });

        Vue.prototype.$spTechApi.interceptors.response.use((response) => {
            mostrouErro = false;
            return response;
        }, 
        error => {
            interceptorMessages(error);
            return Promise.reject(error);
        });
    }
})
