
import Vue from 'vue'
import { showToast } from '@/utilities/Utils'

import { pedidoFormatServer } from "./pedidoFormatServer";

const getDefaultState = () => {
    return {
        pedido: {
            id: 0,
            dataCadastro: "",
            idUsuarioCadastro: 0,
            status: 1,
            idDistribuidor: 0,
            valorTotalBruto: 0,
            valorTotal: 0
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async List() {

        try {
            const result = await Vue.prototype.$spTechApi.get("pedido/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listPedido = result.data.result;

                return listPedido;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar pedidos: [${ex}]`
            };
        }
    },

    async GetById(_, id) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`pedido/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Erro ao obter pedido`
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                showToast("error", "Erro!", error.response.data);
            }
            else {
                showToast("error", "Erro!", error);
            }
        }
    },

    async GetByGuid(_, guid) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`pedido/get-by-guid/${guid}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Erro ao obter pedido`
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                showToast("error", "Erro!", error.response.data);
            }
            else {
                showToast("error", "Erro!", error);
            }
        }
    },

    async CreateUpdate(_, pedidoRequest) {

        try {
            let pedidoRequestFormatted = pedidoFormatServer(pedidoRequest);

            const result = await Vue.prototype.$spTechApi.post(`pedido/create-update`, pedidoRequestFormatted,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                let msg = "Registro atualizado com sucesso!";

                if (pedidoRequest.id === 0) {
                    msg = "Registro criado com sucesso!";
                }

                return {
                    success: true,
                    idPedido: result.data.result,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao criar/atualizar o registro!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Erro ao criar/atualizar o registro: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Erro ao criar/atualizar o registro: [${error}]`
                };
            }
        }
    },

    async AlterarStatus(_, updateStatusRequest) {

        try {
            const result = await Vue.prototype.$spTechApi.put(`pedido/update-status/${updateStatusRequest.id}`, updateStatusRequest, 
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Pedido atualizado com sucesso!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao atualizar o status!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao atualizar o status: [${ex}]`
            };
        }
    },

    async GetLog(_, idPedido) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`pedido/get-log/${idPedido}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listLogPedido = result.data.result;

                return listLogPedido;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar log de pedidos: [${ex}]`
            };
        }
    },

    async ConsultaStatusPedido() {

        try {
            Vue.prototype.$spTechApi.put("pedido/atualizar-status-pedido",
            {
                headers: {
                    "content-type": "application/json"
                }
            });
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao status dos pedidos: [${ex}]`
            };
        }
    },

    async ListStatus() {

        try {
            const result = await Vue.prototype.$spTechApi.get("pedido/list-status",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listStatus = result.data.result;

                return listStatus;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar status de pedidos: [${ex}]`
            };
        }
    },

    async VerificarCertificacaoEX(_, listItens) {

        try {
            const result = await Vue.prototype.$spTechApi.post("pedido/verificar-certificacao-ex", listItens,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listItensCertificacaoEX = result.data.result;

                return listItensCertificacaoEX;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao verificar itens com certificação ex: [${ex}]`
            };
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.pedido, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.pedido;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
