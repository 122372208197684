import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

import logIn from "./usuario/logIn";
import usuario from "./usuario/usuario";
import perfil from "./usuario/perfil";
import menu from "./menu/menu";
import estado from "./comum/estado";
import ncm from "./comum/ncm";
import empresa from "./empresa/empresa";
import grandeza from "./produto/grandeza";
import marca from "./produto/marca";
import tipoProduto from "./produto/tipoProduto";
import familia from "./produto/familia";
import cambio from "./cambio/cambio";
import unidadeMedida from "./produto/unidadeMedida";
import produto from "./produto/produto";
import parametro from "./produto/parametro";
import carrinho from "./vendas/carrinho";
import desconto from "./comum/desconto";
import orcamento from "./orcamento/orcamento";
import pedido from "./pedido/pedido";
import campanhaEstoque from "./campanha/campanhaEstoque";
import campanhaCredito from "./campanha/campanhaCredito";
import campanhaOuroPrata from "./campanha/campanhaOuroPrata";
import condicaoPagamento from "./comum/condicaoPagamento";
import formaPagamento from "./comum/formaPagamento";
import tipoFrete from "./comum/tipoFrete";
import distribuidorConfig from "./distribuidor/distribuidorConfig";
import keyAccount from "./keyAccount/keyAccount";
import dashboard from "./dashboard/dashboard";
import pedidoNotificacaoEmail from "./pedido/pedidoNotificacaoEmail";
import relatorios from "./relatorios/relatorios";
import totvs from "./comum/totvs";
import anexo from "./anexo/anexo";
import transportadora from "./comum/transportadora";
import configuracaoSistema from "./configuracaoSistema/configuracaoSistema";
import analiseCredito from "./analiseCredito/analiseCredito";
import segmento from "./comum/segmento";
import subSegmento from "./comum/subSegmento";
import vendedor from "./comum/vendedor";
import municipio from "./comum/municipio";
import pais from "./comum/pais";
import cidade from "./comum/cidade";

export default new Vuex.Store({
    modules: {
        logInModule: logIn,
        usuarioModule: usuario,
        perfilModule: perfil,
        menuModule: menu,
        estadoModule: estado,
        empresaModule: empresa,
        grandezaModule: grandeza,
        marcaModule: marca,
        tipoProdutoModule: tipoProduto,
        familiaModule: familia,
        cambioModule: cambio,
        unidadeMedidaModule: unidadeMedida,
        produtoModule: produto,
        parametroModule: parametro,
        ncmModule: ncm,
        carrinhoModule: carrinho,
        descontoModule: desconto,
        orcamentoModule: orcamento,
        pedidoModule: pedido,
        campanhaEstoqueModule: campanhaEstoque,
        campanhaCreditoModule: campanhaCredito,
        campanhaOuroPrataModule: campanhaOuroPrata,
        condicaoPagamentoModule: condicaoPagamento,
        formaPagamentoModule: formaPagamento,
        tipoFreteModule: tipoFrete,
        distribuidorConfigModule: distribuidorConfig,
        keyAccountModule: keyAccount,
        dashboardModule: dashboard,
        pedidoNotificacaoEmailModule: pedidoNotificacaoEmail,
        relatoriosModule: relatorios,
        totvsModule: totvs,
        anexoModule: anexo,
        transportadoraModule: transportadora,
        configuracaoSistemaModule: configuracaoSistema,
        analiseCreditoModule: analiseCredito,
        segmentoModule: segmento,
        subSegmentoModule: subSegmento,
        vendedorModule: vendedor,
        municipioModule: municipio,
        paisModule: pais,
        cidadeModule: cidade,
    },

    state: {
        loader: false,
        menuSide: true
    },
    
    mutations: {
        Loader(state, payload) {
            state.loader = payload;
        },

        OpenMenuSide(state, payload) {
            state.menuSide = payload
        }
    }
})