import VMoney from './directive'
import options from './options'
import Vue from 'vue'

export {
  VMoney,
  options
}

function install (Vue, globalOptions) {

  if (globalOptions) {
    Object.keys(globalOptions).map(function(key){
      options[key] = globalOptions[key]
    })
  }

  Vue.directive('formatMoney', VMoney);

}

export default install

Vue.use(install)
